* {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

html, body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  overflow: scroll;
}

main, .block-cb, .block-two-button, .block-two-w-button, .block-home-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  max-width: 440px;
  width: 100vw;
  min-height: 97vh; /* Используйте min-height вместо height */
  border-radius: 100px 100px 0 0;
  background: #303030 url() no-repeat center/cover;
  border-top: 4px solid #dc1fff;
  box-shadow: 0 0 35px #9945ff, 0 0 55px #07f0a2c7, 0 0 100px rgba(0, 255, 255, 1.2);
  margin-top: 2vh;
}


.block-send {
  display: flex;
  align-items: center;
  background-color: #202020;
  border: 1px solid #202020;
  padding: 10px;
  width: 250px;
  justify-content: space-around;
  margin: 20px auto;
  border-radius: 25px;
}

.block-invite {
  display: flex;
  align-items: center;
  background-color: #202020;
  border: 1px solid #202020;
  padding: 10px;
  width: 350px;
  justify-content: space-around;
  margin: 20px auto;
  border-radius: 10px;
}
.block-invite .text-home-1 {
  margin-bottom: 3px;
  font-size: 16px;;
}
.block-invite .text-home-2 {
  font-size: 12px;;
}
.block-text-center, .text-header-mission {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 15px auto;
}

.text-header-mission {
  color:#ffffff;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.block-text-center h2 {
  color:#ffffff;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.block-text-center p {
  color:#ffffff;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.block-text-list-friends {
  display: flex;
  padding: 5px;
  width: 350px;
  margin: 5px auto;
  border-radius: 10px;
}

.text-friends {
  font-size: 18px;
  color: #ffffff;
}
.block-f {
  display: flex;
  align-items: center;
  background-color: #202020;
  border: 1px solid #202020;
  padding: 10px;
  width: 350px;
  justify-content: space-around;
  margin: 15px auto;
  border-radius: 10px;
}

.block-f p {
  color:#ffffff;
  font-size: 14px;
  font-weight: 500;
}
.block-wallet-list {
  display: flex;
  align-items: center;
  background-color: #202020;
  border: 1px solid #202020;
  padding: 8px;
  width: 350px;
  margin: 8px auto;
  border-radius: 15px;
}
.w-350 {
  width: 350px;
}
.block-send-position {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
}

.button {
  margin-right: 10px;
  margin-left: 10px;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  font-size: 12px;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}
.super-button {
  margin-right: 10px;
  margin-left: 10px;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  font-size: 12px;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color:#03cf8b;
  color: rgb(255, 255, 255); /* Цвет текста */
  outline: none;
}
.w-25p {
  width: 25%;
}
.button:hover, .super-button:hover {
  opacity: 0.8;
}

.image {
  width: 35px;
}
.image-mis {
  width: 20px;
}
.image-missions {
  width: 70px;
}
.img-pulse {
  animation: pulse1 2s infinite, scalePulse 2s infinite;
}
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-home-1, .text-home-2 {
  color: white;
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-bold {
  font-weight: bold;
}
.block-image, .block-boost-timer {
  display: flex;
  padding: 10px;
  justify-content: space-around;
}
.mission-img {
  display: flex;
  align-items: center;
  margin: 3px 0 0 0;
}
.m-10 {
  margin: 0 5px 0 0;
}
.solana-image {
  margin: 15px 0;
  width: 250px;
  height: auto;
  border-radius: 50%;
  animation: pulse 2s infinite, spin var(--spin-duration, 5s) linear infinite;
  transition: filter 0.5s ease-in-out, --spin-duration 1s ease-in-out;
}
.solana-image.clicked {
  filter: brightness(250%);
}
.boost-image {
  margin: 2px 0;
  width: 60px;
  height: 60px;
  display: block;
  object-fit: cover;
  animation: pulse1 2s infinite, scalePulse 2s infinite;
}
.boost-timer {
  display: flex;
  justify-content: space-around;
  background-color: #202020;
  border: 2px solid #202020;
  border-radius: 15px;
  padding: 15px 15px;
  width: 100px;
}
.boost-timer span {
  color: #ffffff;
  font-size: 18px;
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0 0 15px #07f0a2c7;
  }
  25%, 75% {
    box-shadow: 0 0 25px #9945ff;
  }
  50% {
    box-shadow: 0 0 25px #dc1fff;
  }
}

@keyframes pulse1 {
  0%, 100% {
    filter: drop-shadow(0 0 1px #07f0a279);
  }
  25%, 75% {
    filter: drop-shadow(0 0 1px #9945ff75);
  }
  50% {
    filter: drop-shadow(0 0 1px #dd1fff4c);
  }
}

@keyframes scalePulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.block-two-button {
  margin: 25px 0;
}
.block-two-button .button {
  width: 40%;
  height: 35px;
}

.block-two-w-button {
  margin: 30px 0;
}
.block-two-w-button .button {
  width: 350px;
  height: 35px;
}
.block-home-button .super-button {
  width: 85%;
  height: 35px;
}


.p-1, .p-2 {
  color: rgba(255, 255, 255, 0.89);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-weight: bold;
  letter-spacing: -1px;
}

.p-1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.p-2 {
  font-size: 1.075rem;
  line-height: 1.25rem;
}
.text-success {
  color: #00fe55d4;
}
.text-white {
  color: #fff;
}
.text-danger {
  color: #d50a0ac9;
}
.bg-white {
  background-color: #c8c8c8;
}
.gray-input {
  width: 100%; /* Занимает всю доступную ширину */
  padding: 6px; /* Внутренний отступ */
  border: 1px solid #202020; /* Серый цвет рамки */
  border-radius: 5px; /* Скругленные углы */
  background-color: #202020; /* Светло-серый фон */
  font-size: 14px; /* Размер шрифта */
  color: #d8d8d8; /* Цвет текста */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Внутренняя тень */
  transition: border-color 0.3s, box-shadow 0.3s; /* Плавные переходы при фокусе */
}

.gray-input:focus {
  border-color: #515151; /* Изменение цвета рамки при фокусе */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Внешняyя тень при фокусе */
  outline: none; /* Убирает стандартную обводку при фокусе */
}

.android-toast {
  background-color: #323232 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  font-size: 10px !important;
  max-width: 150px !important; /* Максимальная ширина */
  width: 100% !important; /* Ширина 100% */
  margin: 2px auto !important; /* Центрирование */
  height: 5px !important;
}

.invert-image {
  filter: invert(1) brightness(1.2) drop-shadow(0 0 1px rgb(152, 152, 152));
}