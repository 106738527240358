.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Фон сразу видим */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: auto; /* добавлено */
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.modal-content {
    border-top: 4px solid #00d18c;
    box-shadow: 0px -10px 35px #00d18c;
    background: #303030;
    border-radius: 80px 80px 0 0;
    position: relative;
    max-width: 440px;
    width: 100%;
    height: 70vh;
    text-align: center;
    animation: slideIn 0.5s ease forwards;
}

.modal-content h2 {
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 5px 0;
}

.modal-content p {
    color: #c2c2c2;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    margin: 8px 0;
}

.modal-content input {
    margin: 5px 0;
    padding: 5px 10px;
    background-color: #c4c4c4;
    width: 85%;
    border: 2px solid #c4c4c4;
    border-radius: 5px;
    height: 35px;
}

.modal-button {
    margin-top: 10px;
    background: #079a69;
    color: #e6e6e6e7;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 90%;
    height: 45px;
    outline: none;
}
.modal-button:hover {
    opacity: 0.8;
  }
.modal-button.bcolor {
    background: #cfcfcf;
    color: #303030;
}

.boost-text {
    font-size: 14px;
    color: #e0e0e0;
    padding: 2px 0;
  }

.boost-text-top {
    font-size: 20px;
    color: #e0e0e0;
    padding: 2px 0;
  }
.modal-close {
    display: none;
}
