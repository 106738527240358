.bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 440px;
  background-color: #3d3d3d;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  left: 50%;
  transform: translateX(-50%);
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #818080; /* Цвет для неактивного элемента */
  font-size: 10px;
  text-decoration: none; /* Убирает подчеркивание ссылок */
  padding: 5px 15px; /* Добавляет отступы вокруг элементов меню */
  border: 2px solid transparent; /* Изначально прозрачная граница для всех элементов */
  border-radius: 10px; /* Закругленные углы для всех элементов */
  -webkit-tap-highlight-color: transparent; /* Убирает выделение при касании */
}

.menu-item .fa-icon {
  font-size: 18px;
  margin-bottom: 0px;
  color: #818080; /* Цвет для неактивного элемента */
}

.menu-item span {
  margin-top: 1px;
}

.menu-item.active {
  color: #fff; /* Цвет для активного элемента */
  background-color: #202020; /* Пример затенения активного элемента */
  border-radius: 15px; /* Закругленные углы для активного элемента */
}

.menu-item.active .fa-icon {
  color: #cacaca; /* Цвет иконки для активного элемента */
}

.menu-item:active {
  outline: none; /* Убирает обводку при активации */
}

.animated-home-icon {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
